import { LogoIcon } from './Ui/Header'
import * as Loader from './Ui/Loader'
import LoaderGif from './Ui/Assets/loader.gif'
import React from 'react'

export default () =>
  <Loader.Container>
    <img src={LoaderGif} alt="loading ..." />
    <LogoIcon />
  </Loader.Container>
