import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

const Wave = ({ isDark = false, hasShadow = false }) => {
  const { colors: { white } } = useContext(ThemeContext)
  const id = `wave_${isDark ? 'dark' : 'white'}`

  return (
    <Svg width="100%" viewBox="0 0 1443 60" version="1.1" hasShadow={hasShadow}>
      <style jsx="true">{`
        #${id} {
          fill: ${isDark ? white.dark : white.median};
        }
      `}</style>
      <path d="M6.46607559e-14,74 L1.60307925e-12,29.61043 C238.497163,69.091004 478.926149,69.091004 721.286958,29.61043 C963.647768,-9.870143 1204.21878,-9.870143 1443,29.61043 L1443,74 L1443,174 L6.46607559e-14,174 L4.54747351e-13,74 L6.46607559e-14,74 Z" id={id} fill="#000000" fillRule="nonzero"></path>
    </Svg>
  )
}

Wave.propTypes = {
  isDark: PropTypes.bool,
  hasShadow: PropTypes.bool,
}

export const Svg = styled.svg`
  position: relative;
  top: 6px;
  z-index: 0;
  ${props => props.hasShadow
    ? 'filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));'
    : ''
  }
`

export default Wave
