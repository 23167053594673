import { createSlice } from 'redux-starter-kit'

/* This state is about the general layout and handle
 * the different changements */

export const MODES = {
  home: 'Home',
  search: 'Search',
  bundle: 'Bundle',
}

export const INITIAL_STATE = {
  mode: MODES.home,
  title: 'KNP Bundles',
  bundleCount: -1,
}

export const slice = createSlice({
  name: 'layout',
  initialState: INITIAL_STATE,
  reducers: {
    changeLayout: (state, { payload }) => ({
      ...state,
      mode: payload,
    }),
    setTitle: (state, { payload }) => ({
      ...state,
      title: payload,
    }),
    loadCount: state => state,
    receivedCount: (state, { payload }) => ({ ...state, bundleCount: payload })
  }
})

export const changeLayout = slice.actions.changeLayout

export const setTitle = slice.actions.setTitle

export const loadCount = slice.actions.loadCount

export const receivedCount = slice.actions.receivedCount

export default slice.reducer
