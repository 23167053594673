import { GoToTopButton, GoToTopIcon, GoToTopLabel } from './Ui/Layout'
import React, { useState, useEffect } from 'react'

/**
 * this component has a completly isolated state and it's not a part
 * of the critical feature of the ui. We can then use a local
 * state to manage scrolling and not global state.
 */
const GoToTop = () => {
  const [ isVisible, setIsVisible ] = useState(false)
  const goToTop = () => window.scrollTo(0, 0)

  useEffect(() => {
    const listener = e => {
      if (window.scrollY > 500) {
        return setIsVisible(true)
      }

      return setIsVisible(false)
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [])

  return (
    <GoToTopButton isVisible={isVisible} onClick={goToTop}>
      <GoToTopIcon />
      <GoToTopLabel>back</GoToTopLabel>
    </GoToTopButton>
  )
}

export default GoToTop