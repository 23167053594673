import { captureException } from '@sentry/browser';

/**
 * execute a simple Query / Mutation to the graphql server.
 *
 * @param {String} query
 * @param {Map String a} variables
 *
 * @return {Promise b}
 */
export const request = (query, variables) =>
  fetch(process.env.REACT_APP_GRAPHQL_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
  .then(response => {
    if (!response.ok) {
      const error = new HttpError(response)

      if (process.NODE_ENV === 'production') {
        captureException(error)
      }

      throw error
    }

    return response.json()
  })
  .then(data => {
    if (data.errors && data.errors.length > 0) {
      const error = new GraphQLError(data.errors[0].message)

      if (process.NODE_ENV === 'production') {
        captureException(error)
      }

      throw error
    }

    return data.data
  })

export class HttpError extends Error {
  constructor (message) {
    super(message)
    this.name = 'HttpError'
  }
}

export class GraphQLError extends Error {
  constructor(message) {
    super(message)
    this.name = 'GraphQLError'
  }
}

export default request
