import React from 'react'
import styled from 'styled-components'
import * as Search from './Ui/Search'
import * as SadFaces from './Ui/SadFaces'

export default () =>
  <ErrorContainer>
    <SadFaces.Shrug />
    <Search.NoResultTitle>
      404
      <br />
      Page not found
    </Search.NoResultTitle>
    <Search.NoResultReason>
      We can't find the page you're asking for.
      <br />
      Try to find your bundle using the search engine.
    </Search.NoResultReason>
  </ErrorContainer>

const ErrorContainer = styled.div`
  padding: 3rem 0;
`
