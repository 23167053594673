import styled from 'styled-components'
import { dotPath } from '../../Util/Functional'
import { Icon } from './Header'

export const Label = styled.label`
  font-weight: 800;
  font-size: 1.2em;
  color: ${dotPath('theme.colors.blue.darker')};
`

export const Select = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(120, 135, 172, 0.35);
  background-color: ${dotPath('theme.colors.white.dark')};
  margin: 0.6em auto 1.2em;
`

export const Button = styled.button`
  float: right;
  width: 190px;
  height: 60px;
  border-radius: 10px;
  box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.12);
  border: solid 3px ${dotPath('theme.colors.white.median')};
  background-color: transparent;
  color: ${dotPath('theme.colors.white.median')};
  font-weight: bold;
  margin-bottom: 1rem;
  outline: none;
  cursor: pointer;

  &:after {
    position: relative;
    top: .1rem;
    left: 1rem;
    font-weight: bold;
    content: "\\e918";
    font-family: knpbundles icons;
    color: ${dotPath('theme.colors.white.median')};
  }

  &:active {
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.12);
  }
`

export const SearchIcon = styled(Icon)`
  font-size: .8rem;
  margin: .6rem .8rem;
  color: ${props => props.theme.colors.grey.light} !important;
  transition: font-size .5s;

  &:before {
    content: "\\e904";
  }
`

export const SearchInput = styled.input`
  border: 0;
  box-shadow: none;
  outline: none;
  margin: .6rem .8rem .4rem .2rem;
  color: ${props => props.theme.colors.grey.light};
  font-size: .9rem;
  min-width: 400px;

  ::placeholder {
    color: ${props => props.theme.colors.grey.lightest};
  }

  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    max-width: 300px;
    min-width: 300px;
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    max-width: 150px;
    min-width: 150px;
  }

  &:hover, &:focus, &:active {
    border: 0;
  }
`

export const SearchForm = styled.form`
  display: flex;
  background-color: ${props => props.theme.colors.white.median};
  border-radius: 1000px;
  box-shadow: -8px 11px 18px -11px rgba(0,0,0,0.57);
  color: ${dotPath('theme.colors.grey.light')};
  align-items: center;
  transition: all .5s;
  flex-direction: row-reverse;

  &.big {
    flex-direction: row;
    max-width: 600px;
    justify-content: space-between;

    ${Button} {
      box-shadow: -8px 11px 18px -11px rgba(0,0,0,.57);
    }

    ${SearchInput} {
      margin: .8rem;
      margin-left: 1rem;
      width: auto;
      flex-grow: 2;
      transition: all .5s;
      pointer-events: auto;
    }

    ${SearchIcon} {
      color: ${props => props.theme.colors.red.median} !important;
      transform: rotateY(0deg) !important;
      font-size: 1.2rem;
      margin: .8rem;
      transform: rotate(90deg);
    }
  }
`

