import { call, put, select, takeLatest, all, fork } from 'redux-saga/effects'
import {
  bundleNotFound,
  fetchBundle,
  fetchSpecificBundleVersion,
  receivedBundle,
  receivedSpecificBundleVersion
} from '../State/Bundle'
import { setTitle } from '../State/Layout'
import { request } from '../Util/Api'

export const selectNameAndPhpVersion = state => ({
  name: state.bundle.name,
  phpVersion: state.bundle.phpVersion,
})

export const selectNameAndVersion = state => ({
  name: state.bundle.name,
  version: state.bundle.version,
})

export function* fetchOneBundle() {
  try {
    const nameAndPhpVersion = yield select(selectNameAndPhpVersion)
    const { 'package': bundle } = yield call(request, FETCH_BUNDLE, nameAndPhpVersion)

    if (bundle === null) throw new Error('Not found')

    yield put(receivedBundle(bundle))
    yield put(setTitle(`${bundle.name} - ${bundle.description}`))
    yield put(fetchSpecificBundleVersion())
  } catch (error) {
    yield put(bundleNotFound())
  }
}

export function* fetchOneSpecificBundleVersion() {
  try {
    const nameAndVersion = yield select(selectNameAndVersion)
    const { version } = yield call(request, FETCH_SPECIFIC_BUNDLE_VERSION, nameAndVersion)

    if (version === null){ throw new Error('Not found') }

    yield put(receivedSpecificBundleVersion(version))
  } catch (error) {
    yield put(bundleNotFound())
  }
}

export function* fetchBundleSaga() {
  yield takeLatest(`${fetchBundle}`, fetchOneBundle)
}

export function* fetchSpecificBundleVersionSaga() {
  yield takeLatest(`${fetchSpecificBundleVersion}`, fetchOneSpecificBundleVersion)
}

export default function* rootSaga () {
  yield all([
    fork(fetchBundleSaga),
    fork(fetchSpecificBundleVersionSaga),
  ])
}

export const FETCH_BUNDLE = `
  query($name: String!, $phpVersion: String!) {
    package(name: $name, phpVersion: $phpVersion) {
      name
      description
      versions
      compatibleVersions
      repository
      favers
      downloads {
        total
      }
    }
  }
`

export const FETCH_SPECIFIC_BUNDLE_VERSION = `
  query($name: String!, $version: String!) {
    version(name: $name, version: $version) {
      phpVersionsSupported
      phpDeprecated
      phpExtensions
      phpDeprecated
      abandoned
      time
      readme
      configYaml
      configXml
    }
  }
`
