import React from 'react'
import Wave from './Ui/Wave'
import { LogoIcon } from './Ui/Header'
import * as Footer from './Ui/Footer'
import LogoWhite from './Ui/Assets/logo-white.svg'
import SymfonyCast from './Ui/Assets/symfonycast.png'
import LogoKnpWhite from './Ui/Assets/logo-knp-white.svg'
import Mail from './Ui/Assets/icons/mail.svg'

export default () => (
  <>
    <Wave />

    <Footer.Background>

    <Footer.UpperWrapper>
      <Footer.ContactUs>
        <img src={Mail} alt="Mail"/>
        <div className="heading">Let's discuss your project</div>
        <div className="subheading">Contact us at <em>hello@knplabs.com</em></div>
      </Footer.ContactUs>
      <Footer.RedInsert>
        <img src={LogoKnpWhite} alt="Logo KNP Labs"/>
        <p>Need help on a topic ?</p>
        <p>Discover our consulting and training offers :)</p>
        <div className="topics">
          <a
            className="topic"
            target="_blank"
            rel="noopener noreferrer"
            href="https://knplabs.com/en/services/trainings"
          >
            <span>&gt;</span>
            <span>Trainings</span>
          </a>
          <a
            className="topic"
            target="_blank"
            rel="noopener noreferrer"
            href="https://knplabs.com/en/services/symfony-agency"
          >
            <span>&gt;</span>
            <span>Consulting</span>
          </a>
          <a
            className="topic"
            target="_blank"
            rel="noopener noreferrer"
            href="https://knplabs.com/en/about-knp"
          >
            <span>&gt;</span>
            <span>Discover us</span>
          </a>
        </div>
      </Footer.RedInsert>

      <Footer.OtherWebsites>
          <a rel="noopener noreferrer" target="_blank" href="https://symfonycasts.com/">
            <img src={SymfonyCast} alt="SymfonyCast logo" />
            <p>Symfony Casts</p>
            <p>The best PHP & Symfony Tutorials</p>
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://knplabs.com/">
            <div className="paw-background">
              <LogoIcon />
            </div>
            <p>KNP Labs</p>
            <p>Happy Awesome Developers</p>
          </a>
      </Footer.OtherWebsites>
    </Footer.UpperWrapper>

    <Footer.BottomWrapper>
      <div className="left">
        <div>
          <span>Terms of use</span>
          <span className="separator">•</span>
          <span>Privacy policy</span>
        </div>

        <div>
          <Footer.SocialMediaLink
            href="https://www.facebook.com/KnpLabs"
            title="KNPLabs on Facebook"
            icon="fa-facebook-square"
          />
          <Footer.SocialMediaLink
            href="https://twitter.com/KNPLabs"
            title="KNPLabs on Twitter"
            icon="fa-twitter-square"
          />
          <Footer.SocialMediaLink
            href="https://www.instagram.com/knplabs"
            title="KNPLabs on Instagram"
            icon="fa-instagram"
            />
        </div>
      </div>

      <div /> {/* Logo placeholder */}

      <div className="right">
        <div className="copyright">Copyright © {new Date().getFullYear()} <em>KnpBundles.com</em> All right reserved</div>
      </div>
      <img src={LogoWhite} alt="Logo"/>
    </Footer.BottomWrapper>
    </Footer.Background>
  </>
)
