import React from 'react'
import { changeQuery, fetchBundles, selectPage, selectPhpVersion, selectQuery } from '../State/Search'
import { useDispatch, useSelector } from 'react-redux'
import {
  SearchForm,
  SearchIcon,
  SearchInput,
} from './Ui/Form'
import { useHistory, useLocation } from 'react-router-dom'

const SearchBar = ({ isBig }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQuery)
  const phpVersion = useSelector(selectPhpVersion)
  const page = useSelector(selectPage)
  const history = useHistory()
  const location = useLocation()

  const handleSubmit = e => {
    e.preventDefault()

    dispatch(fetchBundles())

    if (location.pathname.startsWith('/search')) {
      history.replace(`/search?page=${page}&q=${query}&phpVersion=${phpVersion}`)

      return
    }

    history.push(`/search?page=${page}&q=${query}&phpVersion=${phpVersion}`)
  }

  const handleChange = e => dispatch(changeQuery({
    history,
    query: e.currentTarget.value
  }))

  return (
    <SearchForm
      className={isBig ? 'big' : ''}
      data-is="search"
      onSubmit={handleSubmit}
    >
      <SearchInput
        placeholder="Ex : Api, Calendar ..."
        onChange={handleChange}
        value={query}
      />
      <SearchIcon />
    </SearchForm>
  )
}

export default SearchBar
