import { useSelector } from 'react-redux'
import React from 'react'
import { clean as cleanMostDownloaded, load as loadMostDownloaded } from '../State/MostDownloaded'
import { clean as cleanMostStarred, load as loadMostStarred } from '../State/MostStarred'
import { useActions } from '../Util/Hooks'
import Card from './Card'
import * as Layout from './Ui/Layout'
import { loadCount } from '../State/Layout'
import Loader from './Loader'
import { homeBundlesSelector } from '../State/HomeSelectors'

const Home = () => {
  useActions([
    loadMostStarred(),
    loadMostDownloaded(),
    loadCount()
  ], [
    cleanMostStarred(),
    cleanMostDownloaded(),
  ])
  const { mostStarred, mostDownloaded } = useSelector(homeBundlesSelector)
  const isMostStarredLoading = useSelector(state => state.mostStarred.loading)
  const isMostDownloadedLoading = useSelector(state => state.mostDownloaded.loading)

  return (
    <Layout.Container>
      <Layout.SectionTitle alignRight={true}>
        Most <Layout.RedText>starred</Layout.RedText>
      </Layout.SectionTitle>
      <div>
        { isMostStarredLoading
          ? <Loader />
          : mostStarred.map(bundle =>
            <Card {...bundle} key={bundle.name} />
          )
        }
      </div>
      <Layout.SectionTitle>
        Most <Layout.RedText>downloaded</Layout.RedText>
      </Layout.SectionTitle>
      <div>
        { isMostDownloadedLoading
          ? <Loader />
          : mostDownloaded.map(bundle =>
            <Card {...bundle} key={bundle.name} />
          )
        }
      </div>
    </Layout.Container>
  )
}

export default Home