import { createGlobalStyle } from 'styled-components'
import knpfonteot from './Fonts/KNP/knp.eot'
import knpfontsvg from './Fonts/KNP/knp.svg'
import knpfontttf from './Fonts/KNP/knp.ttf'
import knpfontwoff from './Fonts/KNP/knp.woff'
import montserrateot from './Fonts/Montserrat/montserrat-v13-latin-regular.eot'
import montserratsvg from './Fonts/Montserrat/montserrat-v13-latin-regular.svg'
import montserratttf from './Fonts/Montserrat/montserrat-v13-latin-regular.ttf'
import montserratwoff from './Fonts/Montserrat/montserrat-v13-latin-regular.woff'
import montserrat500eot from './Fonts/Montserrat/montserrat-v13-latin-500.eot'
import montserrat500svg from './Fonts/Montserrat/montserrat-v13-latin-500.svg'
import montserrat500ttf from './Fonts/Montserrat/montserrat-v13-latin-500.ttf'
import montserrat500woff from './Fonts/Montserrat/montserrat-v13-latin-500.woff'
import montserrat700eot from './Fonts/Montserrat/montserrat-v13-latin-700.eot'
import montserrat700svg from './Fonts/Montserrat/montserrat-v13-latin-700.svg'
import montserrat700ttf from './Fonts/Montserrat/montserrat-v13-latin-700.ttf'
import montserrat700woff from './Fonts/Montserrat/montserrat-v13-latin-700.woff'
import montserrat900eot from './Fonts/Montserrat/montserrat-v13-latin-900.eot'
import montserrat900svg from './Fonts/Montserrat/montserrat-v13-latin-900.svg'
import montserrat900ttf from './Fonts/Montserrat/montserrat-v13-latin-900.ttf'
import montserrat900woff from './Fonts/Montserrat/montserrat-v13-latin-900.woff'
import nunitoeot from './Fonts/NunitoSans/nunito-sans-v4-latin-regular.eot'
import nunitosvg from './Fonts/NunitoSans/nunito-sans-v4-latin-regular.svg'
import nunitottf from './Fonts/NunitoSans/nunito-sans-v4-latin-regular.ttf'
import nunitowoff from './Fonts/NunitoSans/nunito-sans-v4-latin-regular.woff'
import nunito600eot from './Fonts/NunitoSans/nunito-sans-v4-latin-600.eot'
import nunito600svg from './Fonts/NunitoSans/nunito-sans-v4-latin-600.svg'
import nunito600ttf from './Fonts/NunitoSans/nunito-sans-v4-latin-600.ttf'
import nunito600woff from './Fonts/NunitoSans/nunito-sans-v4-latin-600.woff'
import nunito700eot from './Fonts/NunitoSans/nunito-sans-v4-latin-700.eot'
import nunito700svg from './Fonts/NunitoSans/nunito-sans-v4-latin-700.svg'
import nunito700ttf from './Fonts/NunitoSans/nunito-sans-v4-latin-700.ttf'
import nunito700woff from './Fonts/NunitoSans/nunito-sans-v4-latin-700.woff'
import nunito800eot from './Fonts/NunitoSans/nunito-sans-v4-latin-800.eot'
import nunito800svg from './Fonts/NunitoSans/nunito-sans-v4-latin-800.svg'
import nunito800ttf from './Fonts/NunitoSans/nunito-sans-v4-latin-800.ttf'
import nunito800woff from './Fonts/NunitoSans/nunito-sans-v4-latin-800.woff'
import workeot from './Fonts/WorkSans/work-sans-v4-latin-regular.eot'
import worksvg from './Fonts/WorkSans/work-sans-v4-latin-regular.svg'
import workttf from './Fonts/WorkSans/work-sans-v4-latin-regular.ttf'
import workwoff from './Fonts/WorkSans/work-sans-v4-latin-regular.woff'
import lucidaGrandeBold from './Fonts/LucidaGrandeBold/LucidaGrandeBold.ttf'
import paytoneOneRegular from './Fonts/PaytonOne/PaytoneOne-Regular.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'knpbundles icons';
    src: url(${knpfonteot});
    src: url('${knpfonteot}?#iefix') format('embedded-opentype'),
         url(${knpfontwoff}) format('woff'),
         url(${knpfontttf}) format('truetype'),
         url('${knpfontsvg}#knp') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(${montserrateot});
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('${montserrateot}?#iefix') format('embedded-opentype'),
         url(${montserratwoff}) format('woff'),
         url(${montserratttf}) format('truetype'),
         url('${montserratsvg}#Montserrat') format('svg');
  }

  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(${montserrat500eot});
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
         url('${montserrat500eot}?#iefix') format('embedded-opentype'),
         url(${montserrat500woff}) format('woff'),
         url(${montserrat500ttf}) format('truetype'),
         url('${montserrat500svg}#Montserrat') format('svg');
  }

  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(${montserrat700eot});
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('${montserrat700eot}?#iefix') format('embedded-opentype'),
         url(${montserrat700woff}) format('woff'),
         url(${montserrat700ttf}) format('truetype'),
         url('${montserrat700svg}#Montserrat') format('svg');
  }

  /* montserrat-900 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(${montserrat900eot});
    src: local('Montserrat Black'), local('Montserrat-Black'),
         url('${montserrat900eot}?#iefix') format('embedded-opentype'),
         url(${montserrat900woff}) format('woff'),
         url(${montserrat900ttf}) format('truetype'),
         url('${montserrat900svg}#Montserrat') format('svg');
  }

  /* nunito-sans-600 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    src: url(${nunito600eot}); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
         url('${nunito600eot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url(${nunito600woff}) format('woff'), /* Modern Browsers */
         url(${nunito600ttf}) format('truetype'), /* Safari, Android, iOS */
         url('${nunito600svg}#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* nunito-sans-regular - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${nunitoeot}); /* IE9 Compat Modes */
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
         url('${nunitoeot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url(${nunitowoff}) format('woff'), /* Modern Browsers */
         url(${nunitottf}) format('truetype'), /* Safari, Android, iOS */
         url('${nunitosvg}#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* nunito-sans-700 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${nunito700eot}); /* IE9 Compat Modes */
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
         url('${nunito700eot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url(${nunito700woff}) format('woff'), /* Modern Browsers */
         url(${nunito700ttf}) format('truetype'), /* Safari, Android, iOS */
         url('${nunito700svg}#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* nunito-sans-800 - latin */
  @font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    src: url(${nunito800eot}); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
         url('${nunito800eot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url(${nunito800woff}) format('woff'), /* Modern Browsers */
         url(${nunito800ttf}) format('truetype'), /* Safari, Android, iOS */
         url('${nunito800svg}#NunitoSans') format('svg'); /* Legacy iOS */
  }

  /* work-sans-regular - latin */
  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${workeot}); /* IE9 Compat Modes */
    src: local('Work Sans'), local('WorkSans-Regular'),
         url('${workeot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url(${workwoff}) format('woff'), /* Modern Browsers */
         url(${workttf}) format('truetype'), /* Safari, Android, iOS */
         url('${worksvg}#WorkSans') format('svg'); /* Legacy iOS */
  }

  /* lucida-grande-bold - latin */
  @font-face {
    font-family: 'Lucida Grande Bold';
    font-style: bold;
    font-weight: 400;
    src: url(${lucidaGrandeBold});
  }

  /* paytone-one regular */
  @font-face {
    font-family: 'PaytoneOne';
    font-style: normal;
    font-weight: 400;
    src: url(${paytoneOneRegular});
  }

  html {
    font-size: 20px;
    scroll-behavior smooth;
  }

  body {
    font-family: "Nunito Sans", "sans-serif";
    font-size: 1rem;
    font-weight: 600;

    /* Make fluid typography (see: https://css-tricks.com/snippets/css/fluid-typography/) */
    @media(min-width: ${props => props.theme.breakpoints.mini}px) {
      font-size: calc(14px + 6 * ((100vw - ${props => props.theme.breakpoints.mini}px) / (${props => props.theme.breakpoints.tablet} - ${props => props.theme.breakpoints.mini})));
    }

    @media(min-width: ${props => props.theme.breakpoints.tablet}px) {
      font-size: 1rem;
    }
  }
`
