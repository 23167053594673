import React from 'react'
import styled from 'styled-components'
import * as SadFaces from './Ui/SadFaces'
import { captureException } from '@sentry/browser';

/**
 * This component catch any render error, display a fallback page
 * to avoid displaying a broken UI, and notify the error manager service.
 *
 * Implemented as a class because it is the only way to use
 * getDerivedStateFromError, wich is the recommended way to implement
 * Error Boundaries.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (process.NODE_ENV === 'production') {
      captureException(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return <Wrapper>
          <SadFaces.TableFlip />
          <h1>Whoops</h1>
          <div>Something went terribly wrong.</div>
          <div>Please refresh the page.</div>
        </Wrapper>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  h1  {
    color: ${props => props.theme.colors.grey.light};
  }

  div:nth-of-type(2), div:nth-of-type(3) {
    color: ${props => props.theme.colors.blue.dark};
    font-family: "Montserrat", "sans-serif";
  }

  div:nth-of-type(3) {
    margin-top: 1em;
  }
`
