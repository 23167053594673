/**
 * This TestStore is made for testing. It decorate an original
 * redux store and collect actions.
 *
 * You may get back dispatched actions with `actions` property.
 *
 * You can also clean the action stack by using the `cleanActions`
 * method.
 */
class TestStore {
  constructor(store) {
    this._store = store
    this._actions = []
    this.dispatch = this.dispatch.bind(this)
    this.getState = this.getState.bind(this)
    this.subscribe = this.subscribe.bind(this)
    this.replaceReducer = this.replaceReducer.bind(this)
    this.cleanActions = this.cleanActions.bind(this)
  }

  dispatch(action) {
    const state = this._store.dispatch(action)

    this._actions.push(action)

    return state
  }

  getState() {
    return this._store.getState()
  }

  subscribe(f) {
    return this._store.subscribe(f)
  }

  replaceReducer(r) {
    return this._store.replaceReducer(r)
  }

  get actions() {
    return this._actions;
  }

  cleanActions() {
    this._actions = []
  }
}

export default store => new TestStore(store)
