import { configureStore } from 'redux-starter-kit'
import createSagaMiddleware from 'redux-saga'
import bundle from './Bundle'
import decorateStore from '../Util/TestStore'
import layout from './Layout'
import mostDownloaded from './MostDownloaded'
import mostStarred from './MostStarred'
import search from './Search'

export const createStore = ({
  saga = emptySaga,
  env = 'development',
} = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: {
      bundle,
      layout,
      mostDownloaded,
      mostStarred,
      search,
    },
    middleware: [ sagaMiddleware ],
    devTools: 'production' !== env,
  })

  // We pass dependencies to our main saga (such as history)
  sagaMiddleware.run(saga, {
    env,
  })

  return 'test' === env
    ? decorateStore(store)
    : store
}

export function* emptySaga () {}

export default createStore
