import React from 'react'
import { NoResultBox, NoResultReason, NoResultTitle } from './Ui/Search';
import * as SadFaces from './Ui/SadFaces'

export const NoResult = () =>
  <NoResultBox>
    <SadFaces.TableFlip />
    <NoResultTitle>
      No results found
    </NoResultTitle>
    <NoResultReason>
      We can't find any bundle matching your search.
      <br />
      Try a more general keyword, or broadening your filters.
    </NoResultReason>
  </NoResultBox>

export default NoResult