import React from 'react'
import Loader from './Loader'
import Card from './Card'
import { useSelector } from 'react-redux'
import { dotPath } from '../Util/Functional'
import NoResult from './NoResult'

const Bundles = () => {
  const loading = useSelector(dotPath('search.loading'))
  const bundles = useSelector(dotPath('search.bundles'))
  const phpVersion = useSelector(dotPath('search.filters.phpVersion'))

  if (loading && !bundles.length) {
    return <Loader />
  }

  if (bundles.length) {
    return bundles.map(bundle => <Card {...bundle} key={bundle.name} phpVersion={phpVersion} />)
  }

  return <NoResult />
}

export default Bundles
