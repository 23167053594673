import { useEffect, useContext } from 'react'
import { __RouterContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'

/**
 * this hooks allows you to dispatch a given action
 *
 * @example
 * ```js
 *  const MyComponent = () => {
 *    useActions(
 *      // will be triggered when effect startup
 *      () => [ loadUser(), loadPersonalInformations() ],
 *      // Clean up function (@see https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup)
 *      () => [ clearLocaleStorage() ]
 *      // The effect dependencies (@see https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects)
 *      [],
 *    )
 *
 *
 *    return <h1>Example</h1>
 *  }
 * ```
 *
 * @param {[ Action ] || () -> [ Action ]} actions
 * @param {[ Action ] || () -> [ Action ]} [cleanActions=[]]
 * @param {[ a ]} [dependencies=[]]
 *
 * @return void
 */
export const useActions = (actions, cleanActions = [], dependencies = []) => {
  const dispatch = useDispatch()

  const triggerActions = actions => typeof actions === 'function'
    ? actions().map(dispatch)
    : actions.map(dispatch)

  useEffect(() => {
    triggerActions(actions)

    if (cleanActions.length === 0 && typeof cleanActions !== 'function') {
      return;
    }

    return () => {
      triggerActions(cleanActions)
    }
    // eslint-disable-next-line
  }, dependencies)
}

/**
 * get back the current router with a hooks.
 *
 * @example
 * const MyComponent = () => {
 *  const router = useRouter()
 *
 *  if (router.history.location.pathname == '/') {
 *    return <p>Home</p>
 *  }
 *
 *  return <p>Not Home</p>
 * }
 *
 * @return Router
 */
export const useRouter = () =>
  useContext(__RouterContext)
