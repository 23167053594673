import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Label, Select } from './Ui/Form'
import { dotPath, map } from '../Util/Functional'
import {
  parseLocation,
  fetchBundles,
  fetchVersions,
  filterPhpVersion,
  reset,
} from '../State/Search'
import { useActions } from '../Util/Hooks'
import * as Layout from './Ui/Layout'
import PageNavigation from './PageNavigation'
import {
  Filters,
  FlexBox,
  LeftColumn,
} from './Ui/Search'
import Bundles from './Bundles'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

const Search = () => {
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))
  const history = useHistory()

  useActions(
    [ parseLocation(params), fetchBundles(), fetchVersions() ],
    [ reset() ]
  )

  const dispatch = useDispatch()

  const handleChangeVersion = e => dispatch(filterPhpVersion({
    history,
    phpVersion: e.target.value
  }))

  const versions = useSelector(dotPath('search.versions'))
  const phpVersion = useSelector(dotPath('search.filters.phpVersion'))

  return (
    <Layout.Container>
      <FlexBox>
        <LeftColumn>
         <Bundles />
        </LeftColumn>

        <Filters>
          <Label htmlFor="php">PHP Versions</Label>
          <Select
            value={phpVersion}
            id="php"
            onChange={handleChangeVersion}>
            <option value="">Choose a version</option>
            { map(v => <option key={v}>{v}</option>)(versions.phpVersions) }
          </Select>
        </Filters>

      </FlexBox>
      <PageNavigation />
    </Layout.Container>
  )
}

export default Search
