import { Provider, useSelector } from 'react-redux'
import { Router, BrowserRouter,  Route } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import PropTypes from 'prop-types'
import React from 'react'
import { GreyBackground } from './Ui/Layout'
import { createStore, emptySaga } from '../State'
import { dotPath } from '../Util/Functional'
import Bundle from './Bundle'
import Footer from './Footer'
import GoToTop from './GoToTop'
import Header from './Header'
import Home from './Home'
import Search from './Search'
import Theme from './Ui/Theme'
import error404 from './Error404'
import rootSaga from '../Effect'
import ErrorBoundary from './ErrorBoundary'
import { MODES, changeLayout, setTitle } from '../State/Layout'
import { useActions } from '../Util/Hooks'
import { ROUTES } from '../constants'

const App = ({
  env = process.env.NODE_ENV,
  store = null,
  path = "",
  history = createMemoryHistory(),
}) => {
  const saga = "test" === env ? emptySaga : rootSaga

  return (
    <Theme>
      <Provider store={store || createStore({ saga, env })}>
        <ErrorBoundary>
          { env === 'test'
            ? (
              <Router history={history}>
                <Route component={RenderApp} />
              </Router>
            )
            : (
              <BrowserRouter>
                <Route component={RenderApp} />
              </BrowserRouter>
            )
          }
        </ErrorBoundary>
        <GoToTop />
      </Provider>
    </Theme>
  )
}

const RenderApp = ({ location }) => {
  const searchQuery = useSelector(dotPath('search.query'))
  const searchTitle = `Search ${searchQuery}`

  return (
    <>
      <Header />
      <GreyBackground>
        <RouterWrapper
          path={ROUTES.home}
          component={Home}
          layout={MODES.home}
          title='Find a bundle that fits your needs with KNPBundles'
          exact={true}
        />
        <RouterWrapper
          path={ROUTES.search}
          component={Search}
          layout={MODES.search}
          title={searchTitle}
          exact={true}
        />
        <RouterWrapper path="/:username/:bundle" component={Bundle} layout={MODES.bundle} exact={true} />
        <RouterWrapper path={ROUTES['404']} component={error404} layout={MODES.home} title="404 - Page not found" />
        <Footer />
      </GreyBackground>
    </>
  )
}

App.propTypes = {
  env: PropTypes.string,
  store: PropTypes.shape({
    getState: PropTypes.func,
    dispatch: PropTypes.func,
    replaceReducer: PropTypes.func,
  }),
  path: PropTypes.string,
}

export default App

/*
  This component extends Route component with the capacity of controlling layout
  mode and page title, so this responsability stay at layout level.
*/

const RouterWrapper = ({
  component: Component,
  layout,
  title,
  ...rest
}) => (
  <Route {...rest} render={props =>
    <ChangeLayout component={Component} layout={layout} title={title} {...props} />
  } />
)

const ChangeLayout = ({
  component: Component,
  layout,
  title,
  ...props
}) => {
  const actions = []

  if (title) {
    actions.push(setTitle(title))
  }

  if (layout) {
    actions.push(changeLayout(layout))
  }

  useActions(actions)

  return <Component {...props} />
}
