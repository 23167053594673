import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import * as Layout from '../State/Layout'
import * as MostDownloaded from '../State/MostDownloaded'
import * as MostStarred from '../State/MostStarred'
import * as Search from '../State/Search'
import request from '../Util/Api'

export function* fetchMostStarred() {
  try {
    const { mostStarredPackages } = yield call(request, FETCH_MOST_STARRED_BUNDLES, { limit: 5 })

    yield put(MostStarred.received(mostStarredPackages))
  } catch (error) {
    // @TODO handle errors correctly
    console.error(error)
  }
}

export function* fetchMostDownloaded() {
  try {
    const data = yield call(request, FETCH_MOST_DOWNLOADED, { limit: 5 })

    yield put(MostDownloaded.received(data.mostDownloadedPackages))
  } catch (error) {
    // @TODO handle errors correctly
    console.error(error)
  }
}

export function* fetchFiltersVersions() {
  try {
    const { filters } = yield call(request, FETCH_FILTERS_VERSIONS, {})

    yield put(Search.receivedVersions(filters))
  } catch (error) {
    // @TODO handle errors correctly
    console.error(error)
  }
}

export function* fetchBundles() {
  try {
    const filters = yield select(Search.selectFilters)
    const { packages } = yield call(request, SEARCH_BUNDLES, filters)
    yield put(Search.receivedBundles(packages))
  } catch (error) {
    // @TODO handle errors correctly
    console.error(error)
  }
}

export function* handleSearch (_, { payload: { history } }) {
  document.documentElement.scrollTop = 0

  const { q, phpVersion, page } = yield select(Search.selectSearchParams)
  yield put(Layout.setTitle(`Search ${q}`))

  if (!history.location.pathname.startsWith('/search')) {
    return
  }

  history.replace(`/search?page=${page}&q=${q}&phpVersion=${phpVersion}`)

  yield put(Search.fetchBundles())
}

export function* debouncedSearch () {
  yield delay(500)

  yield put(Search.fetchBundles())
}

export function* fetchCount() {
  try {
    const { packageCount } = yield call(request, FETCH_COUNT)

    yield put(Layout.receivedCount(packageCount))
  } catch (error) {
    console.error(error)
  }
}


export default function* rootSaga (deps) {
  yield takeLatest(Search.fetchBundles, fetchBundles)
  yield takeLatest(MostStarred.load, fetchMostStarred)
  yield takeLatest(MostDownloaded.load, fetchMostDownloaded)
  yield takeLatest(Layout.loadCount, fetchCount)
  yield takeLatest(Search.fetchVersions, fetchFiltersVersions)
  yield takeLatest(Search.changeQuery, debouncedSearch)
  yield takeLatest(
    [Search.filterPhpVersion, Search.filterPage],
    handleSearch,
    deps
  )
}

export const SEARCH_BUNDLES = `
query($q: String, $phpVersion: String, $first: Int, $offset: Int) {
  packages(
    q: $q
    phpVersion: $phpVersion
    first: $first
    offset: $offset
  ) {
    total
    packages {
      name
      description
      lastUpdate
      favers
      downloads {
        total
      }
      phpVersionsSupported
      versions
    }
  }
}

`

export const FETCH_MOST_STARRED_BUNDLES = `
  query ($limit: Int) {
    mostStarredPackages (limit: $limit) {
      name
      description
      lastUpdate
      favers
      downloads {
        total
      }
      phpVersionsSupported
    }
  }
`

export const FETCH_MOST_DOWNLOADED = `
  query ($limit: Int){
    mostDownloadedPackages (limit: $limit) {
      name
      description
      lastUpdate
      favers
      downloads {
        total
      }
      phpVersionsSupported
    }
  }
`

export const FETCH_FILTERS_VERSIONS = `
  query {
    filters {
      phpVersions
    }
  }
`

export const FETCH_COUNT = `
  query {
    packageCount
  }
`
