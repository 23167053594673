import { createSlice } from 'redux-starter-kit'

export const Tab = {
  README: 'README',
  CONFIG: 'CONFIG',
}

export const INITIAL_STATE = {
  name: '',
  loading: true,
  loadingSpecificVersion: true,
  notFound: false,
  version: '',
  phpVersion: '',
  activeTab: Tab.README,
  data: {
    name: '',
    description: '',
    versions: [],
    compatibleVersions: [],
    favers: 0,
    repository: '',
    downloads: {
      total: 0
    }
  },
  versionSpecific: {
    phpVersionsSupported: [],
    phpDeprecated: false,
    phpExtensions: [],
    deprecated: false,
    abandoned: false,
    readme: '',
  }
}

export const slice = createSlice({
  name: 'bundle',
  initialState: INITIAL_STATE,
  reducers: {
    fetchBundle: (state, { payload: { name, phpVersion } }) => ({
      ...INITIAL_STATE,
      name: name,
      phpVersion: phpVersion,
    }),
    receivedBundle: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
      notFound: false,
      version: payload.compatibleVersions[0],
    }),
    bundleNotFound: state => ({
      ...INITIAL_STATE,
      notFound: true,
      loading: false,
    }),
    fetchSpecificBundleVersion: (state, { payload }) => ({
      ...state,
      version: payload || state.version,
      loadingSpecificVersion: true,
      versionSpecific: INITIAL_STATE.versionSpecific,
    }),
    receivedSpecificBundleVersion: (state, { payload }) => ({
      ...state,
      loadingSpecificVersion: false,
      versionSpecific: payload,
    }),
    clean: (state, action) => INITIAL_STATE,
    changeTab: (state, { payload }) => ({
      ...state,
      activeTab: payload
    })
  },
})

export const {
  fetchBundle,
  receivedBundle,
  bundleNotFound,
  fetchSpecificBundleVersion,
  receivedSpecificBundleVersion,
  changeContentType,
  clean,
  changeTab,
} = slice.actions

export default slice.reducer
