import { Icon } from './Header'
import { Link } from 'react-router-dom'
import { dotPath } from '../../Util/Functional'
import styled from 'styled-components'

/**
 * this file contains all needed component to display a card
 */

export const Card = styled(Link)`
  --shadow: ;
  background-color: ${props => props.theme.colors.white.median};
  padding: .6rem;
  box-shadow: 0px 0px 18px -11px rgba(0,0,0,0.57);
  margin-bottom: .8rem;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  display: block;

  &:visited, &:hover {
    outline: none;
  }
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Title = styled.h4`
  color: ${props => props.theme.colors.blue.median};
  font-weight: 800;
  margin: .4rem 0;
  margin-top: 0;
  font-size: 1.1rem;
`

export const Description = styled.p`
  color: ${props => props.theme.colors.grey.light};
  font-size: .8rem;
  margin: .4rem 0;
`

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media(max-width: ${dotPath('theme.breakpoints.mobile')}px) {
    display: none;
  }
`

export const Badge = styled.div`
  background-color: ${props => props.theme.colors.white.dark};
  padding: .3rem;
  margin: 0 .6rem;
  color: ${props => props.theme.colors.grey.light};
  font-size: .8rem;

  i {
    margin-right: .4rem;
  }
`

export const IconDownload = styled(Icon)`
 &:before {
   content: '\\e911';
 }
`

export const IconStar = styled(Icon)`
  &:before {
    content: '\\e910';
  }
`

export const TagLine = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const TagLabel = styled.p`
  font-size: .7rem;
  margin: 0 .4rem;
  color: ${dotPath('theme.colors.blue.darker')};
`

export const TagLastUpdate = styled.p`
  font-size: .7rem;
  margin: 0 .2rem;
  color: ${dotPath('theme.colors.grey.light')};
  font-style: italic;
`

export const TagCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 20px;
`

export const TagVersion = styled.div`
  padding: .2rem;
  margin: 0rem .2rem;
  font-weight: 800;
  font-size: .6rem;
  border-radius: 2px;
`

export const TagMore = styled.p`
  font-size: .7rem;
  margin: 0 .4rem;
  color: ${dotPath('theme.colors.blue.darker')}
`

export const PhpLine = styled(TagLine)`
  margin: .4rem 0;

  ${TagCircle} {
    background-color: ${props => props.theme.colors.violet};
  }

  ${TagVersion} {
    border: 1px solid ${props => props.theme.colors.violet};
    color: ${props => props.theme.colors.violet};
  }

  ${TagMore} {
    color: ${props => props.theme.colors.violet};
  }
`
