import React from 'react'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './GlobalStyle'

/**
 * this file combine all dynamic css properties used
 * to design the application and export a single
 * component `Theme` embeding the application theme.
 */

export const theme = {
  containerWidth: 1100,
  colors: {
    cyan: '#6ed3cf',
    violet: '#8892bf',
    white: {
      median: '#ffffff',
      dark: '#f4f5f7',
    },
    black: '#000000',
    blue: {
      light: '#95e5eb',
      median: '#163262',
      dark: '#17325d',
      darker: '#3a3a5e',
    },
    red: {
      light: '#e06870',
      median: '#ff2f2d',
      dark: '#d6261b',
      darker: '#8c1a12',
    },
    grey: {
      lightest: '#c2cbda',
      light: '#7887ac',
    },
    symfony: '#6ed3cf',
    php: '#8892bf',
  },
  breakpoints: {
    mini: 320,
    mobile: 640,
    tablet: 1024,
  }
}

export default ({ children }) =>
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      { children }
    </>
  </ThemeProvider>
