import 'normalize.css'
import 'font-awesome/css/font-awesome.css'
import React from 'react'
import { createBrowserHistory } from 'history'
import { render } from 'react-dom'
import App from './Component/App'
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  project: 'knpbundles-frontend',
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

const root = document.getElementById('root')
const history = createBrowserHistory()

if (!root) {
  console.error('Fatal Error: no #root element is present in the DOM. Can not boot the application properly.')
} else {
  render(<App history={history}/>, root)
}
