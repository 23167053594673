import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Background,
  Label,
  Logo,
  MainTitleContainer,
  Subheading,
  Title,
} from './Ui/Header'
import { MODES } from '../State/Layout'
import { dotPath } from './../Util/Functional'
import { BundleHeader } from './Bundle'
import Wave from './Ui/Wave'
import LogoWhite from './Ui/Assets/logo-white.svg'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'

const Header = () => {
  const mode = useSelector(dotPath('layout.mode'))
  const bundle = useSelector(dotPath('bundle'))
  const bundleCount = useSelector(dotPath('layout.bundleCount'))

  return (
    <Background
      mode={mode}
      height={ MODES.bundle === mode
        ? (bundle.data.abandoned || bundle.versionSpecific.deprecated) ? '400' : '350'
        : ''
      }
    >
      <Logo>
        <Link to="/">
          <img src={LogoWhite} alt="logo" />
        </Link>
      </Logo>
      <MainTitleContainer>
        { MODES.home === mode
          ? (<>
            <div className={ `title-wrapper ${bundleCount > -1 ? 'show' : ''}` }>
              <Title>
                Over {bundleCount} bundles
              </Title>
              <Subheading>
                Find one that fits your needs!
              </Subheading>
            </div>
          </>)
          : null
        }

        <SearchBar isBig={mode === MODES.home} />

        { MODES.home === mode
          ? (
            <Label>
              Created with <strong>♥</strong> by <strong><a href="https://www.knplabs.com/">KNP</a></strong>
            </Label>
          )
          : null
        }

      </MainTitleContainer>
      <Footer mode={mode} bundle={bundle} />
    </Background>
  )
}

function Footer({ mode, bundle }) {
  switch (mode) {
    case MODES.bundle:
      return <BundleHeader bundleInfo={bundle} />
    case MODES.home:
      return <Wave isDark />
    default:
      return null
  }
}

Header.propTypes = {
  isBanner: PropTypes.bool,
}

export default Header
