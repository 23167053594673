import styled, { css } from 'styled-components'
import { Title } from './Header'
import { dotPath } from '../../Util/Functional'
import * as Loader from './Loader'
import { Container as LayoutContainer } from './Layout'

export const Header = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @media(max-width: ${dotPath('theme.breakpoints.mobile')}px) {
    justify-content: center;
  }

  @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) {
    flex-wrap: wrap;
    justify-content: space-evenly;

    & > div {
      flex: inherit;
      width: 100%
    }
  }
`

export const Info = styled.div`
  flex: 0 0 685px;
  color: ${props => props.theme.colors.white.median};
`

export const Aside = styled.div`
  flex: 0 0 300px;
  margin-left: 35px;
  z-index: 2;

  @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) {
    position: relative;
    flex: 1 1 100%;
    margin-left: 0px;
    margin-bottom: 2em;
  }
`

export const Name = styled(Title)`
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
  line-height: 1.3rem;

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    font-size: 1.4rem;
  }
`

export const Description = styled.h2`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0rem;
  text-align: left;
  line-height: 1.3rem;
`

export const Deprecated = styled.div`
  margin-top: 1rem;
  background-color: ${props => props.theme.colors.blue.dark};
  width: 150px;
  height: 30px;
  line-height: 27px;
  border-radius: 20px;
  color: ${props => props.theme.colors.grey.lightest};
  font-size: .8rem;

  &:before {
    position: relative;
    font-family: 'knpbundles icons';
    content: "\\e915";
    color: red;
    top: 2px;
    padding: 0 .6rem;
  }
`

export const Source = styled.div`
  margin-top: 1.3rem;
  height: 40px;
  width: fit-content;
  padding-right: 1rem;
  line-height: 17px;
  border-radius: 100px;
  border: solid 1px ${props => props.theme.colors.white.median};
  font-style: italic;
  font-size: .9rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 1rem);
  overflow: hidden;

  &:before {
    position: relative;
    font-family: 'knpbundles icons';
    font-size: 40px;
    content: "\\e90e";
    color: ${props => props.theme.colors.white.median};
    top: 12px;
    padding-right: .8rem;
    font-style: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.white.median};
  font-weight: 900;
  font-size: 1.1rem;
  text-align: center;
  line-height: 46px;

  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    margin-top: 1.5rem;
  }
`

export const Stat = styled.div`
  width: 140.4px;
  height: 50px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.red.median};

  &:before {
    font-weight: normal;
    font-family: 'knpbundles icons';
    padding-right: .5rem;
  }

  &:first-of-type::before {
    content: "\\e911";
  }

  &:last-of-type::before {
    content: "\\e910";
  }
`

export const Card = styled.div`
  min-height: 300px;
  border-radius: 5px;
  box-shadow: -5px 5px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white.median};
  padding: 20px 28px;
`

export const Dependencies = styled.div`
  border-radius: 2px;
  background-color: ${dotPath('theme.colors.white.dark')};
  margin: .6em auto 1.2em;
  padding: 0.6em;
  color: ${props => props.theme.colors.blue.dark};
  font-size: .8rem;
`

export const DependenciesItem = styled.li`
  display: inline-block;
  margin: .4rem .1rem;
  padding: .2rem .3rem;
  border-radius: 2px;
  border: solid 1px rgba(136, 146, 191, 0.5);
  background-color: ${props => props.theme.colors.white.median};
  font-size: .6rem;
  font-weight: 600;
`

const DependenciesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${DependenciesItem} {
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const PhpDependencies = styled(DependenciesList)`
  color: ${props => props.theme.colors.php};
`

export const SubMenu = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  width:100%;
  height: 55px;
  background-color: ${props => props.theme.colors.white.median};
  box-shadow: -5px 5px 12px 0 rgba(0, 0, 0, 0.08);
`

export const InnerNav = styled.button`
  background: none;
  border: 0;
  user-select: none;
  cursor: pointer;
  outline: none;
  color: ${props => props.theme.colors.grey.light};

  &[data-selected=true] {
    color: ${props => props.theme.colors.red.median};
  }

  &:first-of-type {
    margin-right: 3rem;
  }

  &:before {
    position: relative;
    top: 2px;
    font-family: 'knpbundles icons';
    padding-right: .4rem;
  }

  &:first-of-type::before {
    content: "\\e90c";
  }

  &:last-of-type::before {
    content: "\\e917";
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.black};
    border-radius: 2px;
  }
`

export const Container = styled(LayoutContainer)`
  position: relative;

  ${Loader.Container} {
    margin-top: 5rem;
  }
`

export const Content = styled.div`
  margin-top: 55px;
  max-width: 685px;
  min-height: 300px;
  font-weight: normal;
  font-size: .9rem;
  color: ${props => props.theme.colors.grey.light};

  img {
    max-width: 685px;
  }

  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    max-width: unset;

    img {
      max-width: unset;
    }
  }
`

export const Config = styled(Content)`
  margin-top: 3.5rem;
  margin-bottom: 2rem;

  div {
    white-space: break-spaces;
    background-color: ${props => props.theme.colors.blue.dark};
    color: ${props => props.theme.colors.blue.light};
    padding: 1rem;
    border-radius: 5px;
  }

  div:nth-of-type(2) {
    margin-top: 1rem;
  }
`

export const ReadMe = styled(Content)`
  padding-top: 1.3rem;

  .octicon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
  }

  .anchor {
    float: left;
    line-height: 1;
    margin-left: -20px;
    padding-right: 4px;
    color: ${props => props.theme.colors.blue.dark};
  }

  .anchor:focus {
    color: ${props => props.theme.colors.blue.dark};
  }

  h1 .octicon-link,
  h2 .octicon-link,
  h3 .octicon-link,
  h4 .octicon-link,
  h5 .octicon-link,
  h6 .octicon-link {
    color: ${props => props.theme.colors.blue.dark};
    vertical-align: middle;
    visibility: hidden;
  }

  h1:hover .anchor,
  h2:hover .anchor,
  h3:hover .anchor,
  h4:hover .anchor,
  h5:hover .anchor,
  h6:hover .anchor {
    text-decoration: none;
  }

  h1:hover .anchor .octicon-link,
  h2:hover .anchor .octicon-link,
  h3:hover .anchor .octicon-link,
  h4:hover .anchor .octicon-link,
  h5:hover .anchor .octicon-link,
  h6:hover .anchor .octicon-link {
    visibility: visible;
  }

  {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: ${props => props.theme.colors.grey.light};
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    font-size: .8rem;
    line-height: 1rem;
    word-wrap: break-word;
  }

  .pl-c {
    color: #6a737d;
  }

  .pl-c1,
  .pl-s .pl-v {
    color: #005cc5;
  }

  .pl-e,
  .pl-en {
    color: #6f42c1;
  }

  .pl-s .pl-s1,
  .pl-smi {
    color: ${props => props.theme.colors.grey.light};
  }

  .pl-ent {
    color: #22863a;
  }

  .pl-k {
    color: #d73a49;
  }

  .pl-pds,
  .pl-s,
  .pl-s .pl-pse .pl-s1,
  .pl-sr,
  .pl-sr .pl-cce,
  .pl-sr .pl-sra,
  .pl-sr .pl-sre {
    color: #032f62;
  }

  .pl-smw,
  .pl-v {
    color: #e36209;
  }

  .pl-bu {
    color: #b31d28;
  }

  .pl-ii {
    background-color: #b31d28;
    color: #fafbfc;
  }

  .pl-c2 {
    background-color: #d73a49;
    color: #fafbfc;
  }

  .pl-c2:before {
    content: "^M";
  }

  .pl-sr .pl-cce {
    color: #22863a;
    font-weight: 700;
  }

  .pl-ml {
    color: #735c0f;
  }

  .pl-mh,
  .pl-mh .pl-en,
  .pl-ms {
    color: #005cc5;
    font-weight: 700;
  }

  .pl-mi {
    color: ${props => props.theme.colors.grey.light};
    font-style: italic;
  }

  .pl-mb {
    color: ${props => props.theme.colors.grey.light};
    font-weight: 700;
  }

  .pl-md {
    background-color: #ffeef0;
    color: #b31d28;
  }

  .pl-mi1 {
    background-color: #f0fff4;
    color: #22863a;
  }

  .pl-mc {
    background-color: #ffebda;
    color: #e36209;
  }

  .pl-mi2 {
    background-color: #005cc5;
    color: #f6f8fa;
  }

  .pl-mdr {
    color: #6f42c1;
    font-weight: 700;
  }

  .pl-ba {
    color: #586069;
  }

  .pl-sg {
    color: #959da5;
  }

  .pl-corl {
    color: #032f62;
    text-decoration: underline;
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  a {
    background-color: transparent;
    color: ${props => props.theme.colors.blue.dark};
  }

  a:active,
  a:hover {
    outline-width: 0;
    color: ${props => props.theme.colors.blue.dark};
  }

  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  h1 {
    font-size: 2em;
    margin: .67em 0;
  }

  img {
    border-style: none;
  }

  code,
  kbd,
  pre {
    font-family: monospace,monospace;
    font-size: 1em;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  input {
    font: inherit;
    margin: 0;
  }

  input {
    overflow: visible;
  }

  [type=checkbox] {
    box-sizing: border-box;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.blue.dark};
  }

  a:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.blue.dark};
  }

  strong {
    font-weight: 600;
  }

  hr {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #dfe2e5;
    height: 0;
    margin: 15px 0;
    overflow: hidden;
  }

  hr:before {
    content: "";
    display: table;
  }

  hr:after {
    clear: both;
    content: "";
    display: table;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  details summary {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    margin-top: 0;
    color: ${props => props.theme.colors.blue.dark};
  }

  h1 {
    font-size: 32px;
  }

  h1,
  h2 {
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h3,
  h4 {
    font-weight: 600;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h5,
  h6 {
    font-weight: 600;
  }

  h6 {
    font-size: 12px;
  }

  p {
    margin-bottom: 10px;
    margin-top: 0;
  }

  blockquote {
    margin: 0;
  }

  ol,
  ul {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  ol ol ol,
  ol ul ol,
  ul ol ol,
  ul ul ol {
    list-style-type: lower-alpha;
  }

  dd {
    margin-left: 0;
  }

  code,
  pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    font-size: 12px;
  }

  pre {
    margin-bottom: 0;
    margin-top: 0;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  .border {
    border: 1px solid #e1e4e8!important;
  }

  .border-0 {
    border: 0!important;
  }

  .border-bottom {
    border-bottom: 1px solid #e1e4e8!important;
  }

  .rounded-1 {
    border-radius: 3px!important;
  }

  .bg-white {
    background-color: #fff!important;
  }

  .bg-gray-light {
    background-color: #fafbfc!important;
  }

  .text-gray-light {
    color: #6a737d!important;
  }

  .mb-0 {
    margin-bottom: 0!important;
  }

  .my-2 {
    margin-bottom: 8px!important;
    margin-top: 8px!important;
  }

  .pl-0 {
    padding-left: 0!important;
  }

  .py-0 {
    padding-bottom: 0!important;
    padding-top: 0!important;
  }

  .pl-1 {
    padding-left: 4px!important;
  }

  .pl-2 {
    padding-left: 8px!important;
  }

  .py-2 {
    padding-bottom: 8px!important;
    padding-top: 8px!important;
  }

  .pl-3,
  .px-3 {
    padding-left: 16px!important;
  }

  .px-3 {
    padding-right: 16px!important;
  }

  .pl-4 {
    padding-left: 24px!important;
  }

  .pl-5 {
    padding-left: 32px!important;
  }

  .pl-6 {
    padding-left: 40px!important;
  }

  .f6 {
    font-size: 12px!important;
  }

  .lh-condensed {
    line-height: 1.25!important;
  }

  .text-bold {
    font-weight: 600!important;
  }

  &:before {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }

  &>:first-child {
    margin-top: 0!important;
  }

  &>:last-child {
    margin-bottom: 0!important;
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
    color: ${props => props.theme.colors.blue.dark};
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  table,
  ul {
    margin-bottom: 16px;
    margin-top: 0;
  }

  hr {
    background-color: #e1e4e8;
    border: 0;
    height: .25em;
    margin: 24px 0;
    padding: 0;
  }

  blockquote {
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding: 0 1em;
  }

  blockquote>:first-child {
    margin-top: 0;
  }

  blockquote>:last-child {
    margin-bottom: 0;
  }

  kbd {
    background-color: #fafbfc;
    border: 1px solid #c6cbd1;
    border-bottom-color: #959da5;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #959da5;
    color: #444d56;
    display: inline-block;
    font-size: 11px;
    line-height: 10px;
    padding: 3px 5px;
    vertical-align: middle;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  h1 {
    font-size: 2em;
  }

  h1,
  h2 {
    border-bottom: 1px solid #eaecef;
    padding-bottom: .3em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: .875em;
  }

  h6 {
    color: #6a737d;
    font-size: .85em;
  }

  ol,
  ul {
    padding-left: 2em;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  li {
    word-wrap: break-all;
    padding-left: 1em;
    text-indent: -.7em;

    &:before {
      position: relative;
      top: 3px;
      content: "• ";
      color: ${props => props.theme.colors.blue.dark};
      font-size: 1.3rem;
    }
  }

  li>p {
    margin-top: 16px;
  }

  li+li {
    margin-top: .25em;
  }

  dl {
    padding: 0;
  }

  dl dt {
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
    margin-top: 16px;
    padding: 0;
  }

  dl dd {
    margin-bottom: 16px;
    padding: 0 16px;
  }

  table {
    display: block;
    overflow: auto;
    width: 100%;
  }

  table th {
    font-weight: 600;
  }

  table td,
  table th {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }

  table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  img {
    background-color: #fff;
    box-sizing: content-box;
    max-width: 100%;
  }

  img[align=right] {
    padding-left: 20px;
  }

  img[align=left] {
    padding-right: 20px;
  }

  code {
    background-color: ${props => props.theme.colors.white.median};
    color: ${props => props.theme.colors.red.light};
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: .2em .4em;
  }

  pre {
    word-wrap: normal;
  }

  pre>code {
    color: ${props => props.theme.colors.blue.light};
    border: 0;
    font-size: 100%;
    margin: 0;
    padding: 0;
    white-space: pre;
    word-break: normal;
  }

  .highlight {
    margin-bottom: 16px;
  }

  .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }

  .highlight pre,
  pre {
    background-color: ${props => props.theme.colors.blue.dark};
    border-radius: 3px;
    font-size: 85%;
    line-height: 1.45;
    overflow: auto;
    padding: 16px;
  }

  pre code {
    background-color: transparent;
    border: 0;
    display: inline;
    line-height: inherit;
    margin: 0;
    max-width: auto;
    overflow: visible;
    padding: 0;
    word-wrap: normal;
  }

  .commit-tease-sha {
    color: #444d56;
    display: inline-block;
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    font-size: 90%;
  }

  .blob-wrapper {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .blob-wrapper-embedded {
    max-height: 240px;
    overflow-y: auto;
  }

  .blob-num {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    color: rgba(27,31,35,.3);
    cursor: pointer;
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    font-size: 12px;
    line-height: 20px;
    min-width: 50px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
    user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 1%;
  }

  .blob-num:hover {
    color: rgba(27,31,35,.6);
  }

  .blob-num:before {
    content: attr(data-line-number);
  }

  .blob-code {
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    vertical-align: top;
  }

  .blob-code-inner {
    color: ${props => props.theme.colors.grey.light};
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    font-size: 12px;
    overflow: visible;
    white-space: pre;
    word-wrap: normal;
  }

  .pl-token.active,
  .pl-token:hover {
    background: #ffea7f;
    cursor: pointer;
  }

  kbd {
    background-color: #fafbfc;
    border: 1px solid #d1d5da;
    border-bottom-color: #c6cbd1;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #c6cbd1;
    color: #444d56;
    display: inline-block;
    font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    line-height: 10px;
    padding: 3px 5px;
    vertical-align: middle;
  }

  :checked+.radio-label {
    border-color: #0366d6;
    position: relative;
    z-index: 1;
  }

  .tab-size[data-tab-size="1"] {
    -moz-tab-size: 1;
    tab-size: 1;
  }

  .tab-size[data-tab-size="2"] {
    -moz-tab-size: 2;
    tab-size: 2;
  }

  .tab-size[data-tab-size="3"] {
    -moz-tab-size: 3;
    tab-size: 3;
  }

  .tab-size[data-tab-size="4"] {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  .tab-size[data-tab-size="5"] {
    -moz-tab-size: 5;
    tab-size: 5;
  }

  .tab-size[data-tab-size="6"] {
    -moz-tab-size: 6;
    tab-size: 6;
  }

  .tab-size[data-tab-size="7"] {
    -moz-tab-size: 7;
    tab-size: 7;
  }

  .tab-size[data-tab-size="8"] {
    -moz-tab-size: 8;
    tab-size: 8;
  }

  .tab-size[data-tab-size="9"] {
    -moz-tab-size: 9;
    tab-size: 9;
  }

  .tab-size[data-tab-size="10"] {
    -moz-tab-size: 10;
    tab-size: 10;
  }

  .tab-size[data-tab-size="11"] {
    -moz-tab-size: 11;
    tab-size: 11;
  }

  .tab-size[data-tab-size="12"] {
    -moz-tab-size: 12;
    tab-size: 12;
  }

  .task-list-item {
    list-style-type: none;
  }

  .task-list-item+.task-list-item {
    margin-top: 3px;
  }

  .task-list-item input {
    margin: 0 .2em .25em -1.6em;
    vertical-align: middle;
  }

  hr {
    border-bottom-color: #eee;
  }

  .pl-0 {
    padding-left: 0!important;
  }

  .pl-1 {
    padding-left: 4px!important;
  }

  .pl-2 {
    padding-left: 8px!important;
  }

  .pl-3 {
    padding-left: 16px!important;
  }

  .pl-4 {
    padding-left: 24px!important;
  }

  .pl-5 {
    padding-left: 32px!important;
  }

  .pl-6 {
    padding-left: 40px!important;
  }

  .pl-7 {
    padding-left: 48px!important;
  }

  .pl-8 {
    padding-left: 64px!important;
  }

  .pl-9 {
    padding-left: 80px!important;
  }

  .pl-10 {
    padding-left: 96px!important;
  }

  .pl-11 {
    padding-left: 112px!important;
  }

  .pl-12 {
    padding-left: 128px!important;
  }
`

export const Time = styled.div`
  color: ${props => props.theme.colors.grey.light};
  font-size: 0.75em;
  margin-bottom: 1.2em;
`

export const Flags = styled.div`
  display: flex;

  > * {
    margin-right: 1em;
  }
`

export const StickySearchWrapper = styled.div`
  display: flex;
  padding: 1rem;
  position: fixed;
  justify-content: center;
  top: -70px;
  transition: all .5s;
  width: 100%;
  z-index: 10;

  ${props => props.isVisible
    ? css`
      transform: translateY(70px);
    `
    : css`
      transform: translateY(0);
    `}
`
