import { createSlice } from "redux-starter-kit";

export const INITIAL_STATE = {
  bundles: [],
  loading: false,
}

export const slice = createSlice({
  name: 'mostStarred',
  initialState: INITIAL_STATE,
  reducers: {
    load: (state, action) => ({
      ...state,
      loading: true,
    }),
    received: (state, { payload }) => ({
      ...state,
      loading: false,
      bundles: payload,
    }),
    clean: (state, action) => INITIAL_STATE,
  },
})

export const load = slice.actions.load

export const received = slice.actions.received

export const clean = slice.actions.clean

export default slice.reducer
