import styled, { css } from 'styled-components'
import { MODES } from '../../State/Layout'
import { dotPath } from '../../Util/Functional'
import background from './Assets/background.jpg'
import smallBackground from './Assets/background-small.jpg'

/**
 * Put here every UI Component related to any headers of the aplication
 * (banner also)
 */

export const Icon = styled.i`
  font-family: 'knpbundles icons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const Logo = styled.div`
  width: 10em;
  position: absolute;
  top: 1.5rem;
  left: 1rem;

  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    display: none;
  }
`

export const LogoIcon = styled(Icon)`
  color: ${props => props.theme.colors.red.median};
  font-size: 1.8rem;
  transition: all .5s;

  &:before {
    content: "\\e90b";
  }
`

export const KnpIcon = styled(Icon)`
  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    display: none;
  }

  color: ${props => props.theme.colors.blue.median}
  font-size: 3.4rem;
  margin-left: .8rem;
  transition: all .5s;

  &:before {
    content: "\\e908";
  }
`

export const BundleIcon = styled(Icon)`
  color: ${props => props.theme.colors.blue.median}
  font-size: 3.4rem;
  margin-left: .2rem;
  transition: all .5s;

  @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) {
    display: none;
  }

  &:before {
    content: "\\e909";
  }
`

export const Button = styled.button`
  border: 0;
  border-radius: 60px;
  box-shadow: -8px 11px 18px -11px rgba(0,0,0,.57);
  outline: none;
  cursor: pointer;
  padding: .5rem;
  position: relative;
  z-index: 1000;
  background-color: rgba(22, 50, 98, 0);
  transition: all .5s;
  width: 50px;
  height: 50px;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 60px;
  max-height: 60px;
  align-items: center;

  &:hover, &:active {
    outline: none;
  }
`

export const FilterIcon = styled(Icon)`
  color: ${props => props.theme.colors.red.median};
  font-size: 1rem;

  &:before {
    content: "\\e90f";
  }
`

export const WaveContainer = styled.div`
  width: 320px;
  height: 20px;
  background-color: ${props => props.theme.colors.white.dark};
  position: relative;
  overflow: hidden;
`

export const WaveLeft = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 1000px;
  background-color: ${props => props.theme.colors.white.median};
  position: absolute;
  top: -20px;
  left: 0px;
`

export const WaveRightContainer = styled.div`
  width: 160px;
  height: 20px;
  background-color: ${props => props.theme.colors.white.dark};
  position: absolute;
  top: 0px;
  left: 160px;
`

export const Background = styled.div`
  transition: padding .5s;

  max-height: ${props => props.height ? props.height+'px' : 'unset'};

  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  position: relative;
  padding-top: ${props => props.mode === 'Search' ? '5rem' : 0};

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    background-image: url(${smallBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    max-height: unset;
  }

  svg {
    position: relative;
    top: 6px;
  }

  ${Icon} {
    color: ${props => props.theme.colors.white.median};
  }


  ${Button} {
    box-shadow: none;
  }

  ${props => [MODES.informations, MODES.search].includes(props.mode)
    && css`
      ${Button} {
        box-shadow: -8px 11px 18px -11px rgba(0,0,0,0.57);
      }
    `
  }

  ${props => [MODES.informations, MODES.search, MODES.bundle].includes(props.mode)
    && css`
      ${HeaderNav} {
        padding-bottom: 0rem;
      }

      ${LogoIcon} {
        color: ${dotPath('theme.colors.red.median')};
      }

      ${KnpIcon} {
        color: ${dotPath('theme.colors.blue.median')};
      }

      ${BundleIcon} {
        color: ${dotPath('theme.colors.blue.median')};
      }

      ${MainTitleContainer} {
        position: absolute;
        top: 28px;
        z-index: 115;
        left: 50%;
        margin-left: -233px;
        padding-bottom: .2rem;


        @media(max-width: ${dotPath('theme.breakpoints.mobile')}px) {
          top: 0px;
          margin-left: -128px;
        }

        @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) and (min-width: ${dotPath('theme.breakpoints.mobile')}px) {
          margin-left: -175px;
        }
      }
    `
  }
`

export const MainTitleContainer = styled.div`
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.containerWidth}px;
  margin: auto;
  padding-bottom: 2rem;
  position: relative;
  z-index: 3;
  opacity: 1;
  transition: all .5s;
  align-items: center;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.white.median};
  }

  .title-wrapper {
    text-align: center;
    transition: opacity .5s;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    padding: .8rem;
  }
`

export const Title = styled.h1`
  font-size: 2.4rem;
  letter-spacing: .01rem;
  color: ${props => props.theme.colors.white.median};
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  margin-bottom: 0;
  text-align: center;
  transition: all .5s;

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    font-size: 2rem;
  }
`

export const Subheading = styled.p`
  color: ${props => props.theme.colors.white.median};
  margin-top: 0;
  margin-bottom: 2rem;
`

export const Label = styled.p`
  font-size: .8rem;
  color: ${props => props.theme.colors.white.median};
`

export const CrossIcon = styled(Icon)`
  color: ${dotPath('theme.colors.white.median')};
  translation: all .5s;
  font-size: 1.2rem;
  display: block;
  width: 20px;
  height: 20px;
  transform: translateY(0px);

  @media(max-width: ${dotPath('theme.breakpoints.mobile')}px) {
    font-size: 1rem;
    font-weight: normal;
  }

  &:before {
    content: "\\e912";
  }
`

export const HeaderNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  padding: .6rem;
  position: relative;
  z-index: 100;
  transition: all .5s;
  width: auto;
  box-sizing: border-box;
  pointer-events: none;

  @media(min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding: 1rem;
  }

  ${Button} {
    pointer-events: auto;
  }

  ${CrossIcon} {
    opacity: 0;
    transition: all .5s;
  }

  ${props => props.white
    ? css`
      ${LogoIcon} {
        color: ${dotPath('theme.colors.red.median')};
      }

      ${KnpIcon} {
        color: ${dotPath('theme.colors.blue.median')};
      }

      ${BundleIcon} {
        color: ${dotPath('theme.colors.blue.median')};
      }
    `
    : ''
  }

  ${props => props.open
    ? css`
      position: fixed;
      width: 100%;
      background-color: ${dotPath('theme.colors.white.median')};

      ${Button} {
        background-color: rgba(22, 50, 98, 1);
        box-shadow: none;
        border-radius: 0px;
        padding: 1.8rem;
        top: -.8rem;
        right: -.8rem;

        @media(min-width: ${dotPath('theme.breakpoints.tablet')}px) {
          top: -1.2rem;
          right: -1rem;
        }
      }

      ${LogoIcon} {
        color: ${dotPath('theme.colors.red.median')};
      }

      ${KnpIcon}, ${BundleIcon} {
        color: ${dotPath('theme.colors.blue.median')};
      }

      ${CrossIcon} {
        transform: translateY(-28px);
        opacity: 1;
      }
    `
    : ''
  }
`

