import styled from 'styled-components'
import { dotPath } from '../../Util/Functional'
import { FontAwesome } from './Layout'

export const Background = styled.div`
  background-color: white;
`

export const UpperWrapper = styled.div`
  margin: auto;
  max-width: 1100px;
`

export const SocialMediaLink = styled(FontAwesome).attrs({ as: 'a', target: "_blank", rel: "noopener noreferrer" })`
  position: relative;
  font-size: 22px;
  color: ${dotPath('theme.colors.white.median')};
  padding: 0 .3rem;
  text-decoration: none;
`

export const OtherWebsites = styled.div`
  display: flex;
  margin-bottom: 3rem;
  margin-top: 2rem;

  img {
    width: 3rem;
  }

  p {
    color: ${props => props.theme.colors.blue.dark};
    margin: 0;
    font-weight: lighter;
    font-family: 'Nunito Sans';
  }

  > a {
    display: grid;
    grid-template: "image heading"
                   "image subheading";
    padding: 0.5rem;
    text-decoration: none;
    width: 50%;

    img, .paw-background {
      grid-area: image;
      margin-left: auto;
      margin-right: 1rem;
    }

    p:first-of-type {
      font-weight:bold;
      font-size: 1.3em;
    }
  }

  .paw-background {
    align-items: center;
    background-color: ${props => props.theme.colors.red.median};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    height: 3rem;
    width: 3rem;

    i {
      color: ${props => props.theme.colors.white.median};
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 1rem;

    > div:first-of-type {
      margin-bottom: 1rem;
    }
  }
`

export const BottomWrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.blue.darker};
  color: white;
  display: flex;
  font-size: 0.7em;
  justify-content: space-between;
  padding: .75rem;
  padding-right: 4rem; /* leave some place for back to top button */

  img {
    position: absolute;
    left: calc(50% - 5rem);
    width: 10rem;
  }

  em {
    color: ${props => props.theme.colors.red.dark};
    font-style: normal;
    font-weight: bold;
  }

  .left {
    align-items: center;
    display: flex;

    > div {
      margin-left: 1rem;
    }
  }

  .separator {
    margin: 0 0.5em;
  }

  @media(max-width: ${props => props.theme.breakpoints.tablet}px) {
    img {
      display: none;
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.mobile}px) {
    .copyright {
      display: none;
    }
  }
`

export const RedInsert = styled.div`
    color: ${props => props.theme.colors.white.median};
    background-color: ${props => props.theme.colors.red.median};
    text-align: center;
    padding: 3rem;
    font-size: 1.2em;

    img {
      width: 8rem;
      margin-bottom: 2rem;
    }

    p {
      margin: 0;
      font-weight: bold;
    }

    .topics {
      display: flex;
      font-size: 0.7em;
      justify-content: center;
      margin-top: 2rem;
    }

    .topic {
      text-decoration: none;
      color: ${props => props.theme.colors.white.median};

      > span:first-of-type {
          border-radius: 50px;
          background-color: #ffffff6b;
          width: 2.5em;
          height: 2.5em;
          display: inline-flex;
          justify-content: center;
          align-items: center;
      }

      > span:last-of-type {
          margin-left: -0.7em;
          white-space: nowrap;
      }
    }

    .topic:nth-of-type(2) {
      margin: 0 1rem;
    }
`

export const ContactUs = styled.div`
  color: ${props => props.theme.colors.blue.dark};
  text-align: center;
  padding: 3rem;

  .heading {
    font-family: 'PaytoneOne';
    font-size: 1.9em;
    margin: 1rem 0;
  }

  .subheading {
    font-size: 1em;

    em {
      font-style: normal;
      font-weight: bold;
      color: ${props => props.theme.colors.red.median};
    }
  }
`
