import { dotPath } from '../../Util/Functional'
import * as Loader from './Loader'
import styled, { css } from 'styled-components'
import { Icon } from './Header'

/**
 * Put here any layout component. Components that will help
 * you building any kind of layout.
 */

export const FontAwesome = styled.span.attrs(({icon}) => ({className: `fab ${icon}`}))`
  font-family: 'fontawesome';
`

export const FlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => props.wrap || 'nowrap'};

  @media(max-width: ${dotPath('theme.breakpoints.mobile')}px) {
    justify-content: center;
  }
`

export const GreyBackground = styled.div`
  background-color: ${props => props.theme.colors.white.dark};
  width: 100%;
  box-sizing: border-box;
`

export const Container = styled.div`
  padding: .6rem;
  max-width: ${props => props.theme.containerWidth}px;
  box-sizing: border-box;
  margin: auto;

  ${Loader.Container} {
    margin: .8rem auto;
  }
`

export const SectionTitle = styled.h2`
  text-align: ${props => props.alignRight ? 'right' : 'left'};
  font-size: 2rem;
  letter-spacing: .01rem;
  color: ${props => props.theme.colors.blue.median};
  font-family: "Montserrat", "sans-serif";
  font-weight: 900;
  margin: .8rem 0;
`

export const RedText = styled.span`
  color: ${props => props.theme.colors.red.median};
`

export const GoToTopButton = styled.button`
  background-color: ${dotPath('theme.colors.blue.median')};
  border: 0;
  transform: skewY(10deg);
  position: fixed;
  bottom: -20px;
  right: 20px;
  min-height: 70px;
  min-width: 50px;
  z-index: 200;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: ${dotPath('theme.colors.white.median')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .5s;

  ${props => props.isVisible
    ? css`
      transform: skewY(10deg) translateY(0);
    `
    : css`
      transform: skewY(10deg) translateY(100px);
    `}
`

export const GoToTopIcon = styled(Icon)`
  display: block;
  font-size: .6rem;
  color: ${dotPath('theme.colors.white.median')};
  transform: skewY(-10deg);
  position: relative;
  top: -10px;

  &:before {
    content: "\\e916";
  }
`

export const GoToTopLabel = styled.p`
  transform: skewY(-10deg);
  color: ${dotPath('theme.colors.white.median')};
  margin: .1rem 0;
  padding: 0;
  font-size: .6rem;
  font-weight: bold;
  font-family: "Montserrat", "sans-serif";
  width: 100%;
  position: relative;
  top: -10px;
`
