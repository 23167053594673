import PropTypes from 'prop-types'
import React from 'react'
import { displayNumber } from '../Util/Functional'
import { formatGraphQLDate } from '../Util/Helper'
import * as Cards from './Ui/Cards'

const Card = ({
  name = '',
  phpVersion = '',
  description = '',
  lastUpdate,
  phpVersionsSupported = [],
  downloads,
  favers,
}) => <Cards.Card to={`/${name}?phpVersion=${phpVersion}`}>
    <Cards.Head>
      <div>
        <Cards.Title>
          {name}
        </Cards.Title>
        <Cards.Description>
          {description}
        </Cards.Description>
      </div>
      <Cards.BadgeContainer>
        {
          downloads?.total != null &&
          <Cards.Badge>
            <Cards.IconDownload />
            {displayNumber(downloads.total)}
          </Cards.Badge>
        }
        {
          favers != null &&
          <Cards.Badge>
            <Cards.IconStar />
            {displayNumber(favers)}
          </Cards.Badge>
        }
      </Cards.BadgeContainer>
    </Cards.Head>
    <Cards.TagLine>
      <Cards.PhpLine>
        <Cards.TagCircle />
        <Cards.TagLabel>PHP</Cards.TagLabel>
        { phpVersionsSupported.slice(0, 3).map(version =>
          <Cards.TagVersion key={`php-v${version}`}>
            {version}
          </Cards.TagVersion>
        )}
        { phpVersionsSupported.length > 3
          ? (
            <Cards.TagMore>
              +{phpVersionsSupported.length - 3} more
            </Cards.TagMore>
          )
          : null
        }
      </Cards.PhpLine>
      {lastUpdate &&
        <Cards.PhpLine>
          <Cards.TagLastUpdate>Last update ● {formatGraphQLDate(lastUpdate)}</Cards.TagLastUpdate>
        </Cards.PhpLine>
      }
    </Cards.TagLine>
  </Cards.Card>

Card.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
  phpVersionsSupported: PropTypes.arrayOf(PropTypes.string),
  downloads: PropTypes.shape({
    total: PropTypes.number,
  }),
  favers: PropTypes.number,
}

export default Card
