import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import {
  changeTab,
  clean,
  fetchBundle,
  fetchSpecificBundleVersion,
  Tab,
} from '../State/Bundle'
import { Container } from './Ui/Layout'
import { Label, Select } from './Ui/Form'
import { dotPath, map } from '../Util/Functional'
import { numFormatter, formatGraphQLDate } from '../Util/Helper'
import { useActions } from '../Util/Hooks'
import * as Bundle from './Ui/Bundle'
import Loader from './Loader'
import { ROUTES } from '../constants'
import { Redirect, useLocation } from 'react-router-dom'
import SearchBar from './SearchBar'
import qs from 'qs'

export const BundleHeader = ({ bundleInfo }) => {
  const dispatch = useDispatch()
  const bundle = bundleInfo.data
  const versionSpecific = bundleInfo.versionSpecific

  const incompatibleVersions = useMemo(() =>
    (bundle.versions.filter(v => !bundle.compatibleVersions.includes(v))),
    [bundle.versions, bundle.compatibleVersions]
  )

  return (<>
    {ReactDOM.createPortal(<BundleStickySearch />, document.querySelector('#root'))}
    <Container>
      <Bundle.Header>
        <Bundle.Info>
          <Bundle.Name>{bundle.name}</Bundle.Name>
          <Bundle.Description>{bundle.description}</Bundle.Description>
          <Bundle.Flags>
          { versionSpecific.abandoned
              && <Bundle.Deprecated>Abandoned</Bundle.Deprecated>
          }
          { versionSpecific.phpDeprecated
              && <Bundle.Deprecated>Deprecated</Bundle.Deprecated>
          }
          </Bundle.Flags>
          <Bundle.Source>
            <a target="_blank" rel="noopener noreferrer" href={bundle.repository}>
              {bundle.repository}
            </a>
          </Bundle.Source>
        </Bundle.Info>
        <Bundle.Aside>
          <Bundle.Stats>
            <Bundle.Stat>{numFormatter(bundle.downloads.total)}</Bundle.Stat>
            <Bundle.Stat>{numFormatter(bundle.favers)}</Bundle.Stat>
          </Bundle.Stats>
          <Bundle.Card>
            <Label htmlFor="version">Version</Label>
            <Select
              value={bundleInfo.version}
              id="version"
              onChange={e => dispatch(fetchSpecificBundleVersion(e.target.value))}
            >
              {incompatibleVersions.length > 0 &&
                <optgroup label={`Not compatible PHP ${bundleInfo.phpVersion}`}>
                  {map(v => <option key={v}>{v}</option>)(incompatibleVersions)}
                </optgroup>
              }
              <optgroup label={`Compatible PHP ${bundleInfo.phpVersion}`}>
                {map(v => <option key={v}>{v}</option>)(bundle.compatibleVersions)}
              </optgroup>
            </Select>
            <Bundle.Time>Last update • { formatGraphQLDate(versionSpecific.time) }</Bundle.Time>

            <Label>Dependencies</Label>

            <Bundle.Dependencies>
              Required PHP Extensions
              <Bundle.PhpDependencies>
                { versionSpecific.phpExtensions == null || versionSpecific.phpExtensions.length === 0
                  ? 'Any'
                  : map(extension =>
                    <Bundle.DependenciesItem key={extension}>{extension}</Bundle.DependenciesItem>
                  )(versionSpecific.phpExtensions)
                }
              </Bundle.PhpDependencies>
            </Bundle.Dependencies>

            <Bundle.Dependencies>
              Supported PHP versions
              <Bundle.PhpDependencies>
                { map(version =>
                  <Bundle.DependenciesItem key={version}>{version}</Bundle.DependenciesItem>
                )(versionSpecific.phpVersionsSupported) }
              </Bundle.PhpDependencies>
            </Bundle.Dependencies>

          </Bundle.Card>
        </Bundle.Aside>
      </Bundle.Header>
    </Container>
  </>)
}

export const BundleStickySearch = () => {
  const [ isVisible, setIsVisible ] = useState(false)

  useEffect(() => {
    const listener = e => {
      if (window.scrollY > 300) {
        return setIsVisible(true)
      }

      return setIsVisible(false)
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [])

  return (
    <Bundle.StickySearchWrapper isVisible={isVisible}>
      <SearchBar />
    </Bundle.StickySearchWrapper>
  )
}

export default ({
  match
}) => {
  const location = useLocation()
  const params = qs.parse(location.search.replace('?', ''))

  useActions([
    fetchBundle({
      name: `${match.params.username}/${match.params.bundle}`,
      phpVersion: params.phpVersion,
    })
  ], [
    clean(),
  ])

  const dispatch = useDispatch()
  const loadingSpecificVersion = useSelector(dotPath('bundle.loadingSpecificVersion'))
  const notFound = useSelector(dotPath('bundle.notFound'))
  const versionSpecific = useSelector(dotPath('bundle.versionSpecific'))
  const activeTab = useSelector(dotPath('bundle.activeTab'))

  if (notFound) {
    return <Redirect to={ROUTES['404']} />
  }

  return (
    <>
      <Bundle.SubMenu>
        <Container>
          <Bundle.InnerNav
            data-selected={ activeTab === Tab.README }
            onClick={ () => dispatch(changeTab(Tab.README)) }
          >readme.md</Bundle.InnerNav>
          { (versionSpecific.configYaml || versionSpecific.configXml) &&
            <Bundle.InnerNav
              data-selected={ activeTab === Tab.CONFIG }
              onClick={ () => dispatch(changeTab(Tab.CONFIG)) }
            >configuration</Bundle.InnerNav>
          }
        </Container>
      </Bundle.SubMenu>
      <Bundle.Container>
        { loadingSpecificVersion && <Loader /> }
        { activeTab === Tab.README && <Bundle.ReadMe dangerouslySetInnerHTML={{__html: versionSpecific.readme}} /> }
        { activeTab === Tab.CONFIG && (
          <Bundle.Config>
            { versionSpecific.configYaml && <div>{versionSpecific.configYaml}</div> }
            { versionSpecific.configXml && <div>{versionSpecific.configXml}</div> }
          </Bundle.Config>
        ) }
      </Bundle.Container>
    </>
  )
}
