import styled from 'styled-components'
import { FlexBox as LayoutFlexBox } from './Layout'
import { dotPath } from '../../Util/Functional'

export const NoResultTitle = styled.h2`
  font-size: 2.2rem;
  text-align: center;
  color: ${dotPath('theme.colors.grey.lightest')};
  font-family: "Montserrat", "sans-serif";
  font-weight: 800;
  margin: .6rem 0;
`

export const NoResultReason = styled.p`
  font-size: .8rem;
  color: ${dotPath('theme.colors.blue.dark')};
  text-align: center;
  margin: .6rem 0;
`

export const NoResultBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: .8rem;
  margin-bottom: .8rem;
`

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 725px;
`

export const FlexBox = styled(LayoutFlexBox)`
  @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) {
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;

    & > div {
      flex: 1 1 100%;
    }
  }
`

export const Filters = styled.div`
  position: sticky;
  top: 0;
  flex: 0 0 260px;
  height: 374px;
  border-radius: 5px;
  box-shadow: -5px 5px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white.median};
  margin-left: 35px;
  padding: 20px 28px;

  @media(max-width: ${dotPath('theme.breakpoints.tablet')}px) {
    position: relative;
    flex: 1 1 100%;
    margin-left: 0px;
    margin-bottom: 2em;
    height: inherit;
  }
`
