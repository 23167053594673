import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, {css} from 'styled-components'
import {
  filterPage,
  selectLastPage,
  selectPage,
  selectPhpVersion,
  selectQuery,
} from '../State/Search'
import { useHistory } from 'react-router-dom'

const startAtOne = (_, index) => index + 1
const isCurrentPage = currentPage => page => page === currentPage
const isAdjacentPage = currentPage => page =>
  page - 1 <= currentPage && page + 1 >= currentPage
const isFirstPage = page => page === 1
const isLastPage = lastPage => page => page === lastPage
const isSinglePage = pages => pages.length === 1

const isSecondPageDisplayed = pages => page => {
  const secondPage = pages[1]

  return isFirstPage(page) && secondPage !== 2
}
const isPenultimateDisplayed = (lastPage, pages) => page => {
  const penultimatePage = pages[pages.length - 2]

  return isLastPage(lastPage)(page) && penultimatePage !== lastPage - 1
}

const joinFilters = (...filters) => (...args) =>
  filters.reduce((acc, filter) => acc || filter(...args), false)

const buildLink = phpVersion => query => page =>
  `/search?page=${page}&q=${query}&phpVersion=${phpVersion}`

const changePage = (dispatch, history, page) => () => {
  dispatch(filterPage({ history, page }))
}

const PageNavigation = () => {
  const query = useSelector(selectQuery)
  const phpVersion = useSelector(selectPhpVersion)
  const currentPage = useSelector(selectPage)
  const lastPage = useSelector(selectLastPage)
  const dispatch = useDispatch()
  const history = useHistory()

  const getLink = buildLink(phpVersion)(query)

  const pageArray = Array.from({ length: lastPage }, startAtOne)
  const pages = lastPage <= 10 ? pageArray : pageArray.filter(
    joinFilters(
      isCurrentPage(currentPage),
      isAdjacentPage(currentPage),
      isFirstPage,
      isLastPage(lastPage),
    ),
  )

  return (
    <Wrapper>
      {!isFirstPage(currentPage) ? (
        <Page>
          <Link
            to={getLink(currentPage - 1)}
            title="Go to previous page"
            onClick={changePage(dispatch, history, currentPage - 1)}
          >{'<'}</Link>
        </Page>
      ) : null}
      {pages.map(page => (
        <Fragment key={page}>
          {isPenultimateDisplayed(lastPage, pages)(page) && !isSinglePage(pages) ? <Dots /> : null}
          <Page active={isCurrentPage(currentPage)(page)}>
            <Link
              to={getLink(page)}
              title={`Go to page ${page}`}
              onClick={changePage(dispatch, history, page)}
            >{page}</Link>
          </Page>
          {isSecondPageDisplayed(pages)(page) && !isSinglePage(pages) ? <Dots /> : null}
        </Fragment>
      ))}
      {!isLastPage(lastPage)(currentPage) ? (
        <Page>
          <Link
            to={getLink(currentPage + 1)}
            title="Go to next page"
            onClick={changePage(dispatch, history, currentPage + 1)}
          >{'>'}</Link>
        </Page>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
`
const Page = styled.li`
  --color: ${p => p.theme.colors.blue.median};
  --background-color: transparent;

  border-radius: 50%;
  background-color: var(--background-color);
  height: 32px;
  position: relative;
  width: 32px;

  a, span {
    align-items: center;
    color: var(--color);
    display: flex;
    font-weight: bold;
    justify-content: center;
    height: 100%;
    text-decoration: none;
    width: 100%;
  }

  a {
    &:hover,
    &:focus {
      --color: red;
    }
  }

  ${p => p.active && activePage}
`
const activePage = css`
  --color: ${p => p.theme.colors.white.median};
  --background-color: ${p => p.theme.colors.blue.median};
`

const Dots = () => <Page aria-hidden="true"><span>...</span></Page>

export default PageNavigation
