import { createSlice } from "redux-starter-kit"

export const INITIAL_STATE = {
  loading: false,
  bundles: [],
}

export const slice = createSlice({
  name: 'mostDownloaded',
  initialState: INITIAL_STATE,
  reducers: {
    load: (state, action) => ({
      ...state,
      loading: true,
    }),
    received: (state, { payload }) => ({
      ...state,
      bundles: payload,
      loading: false,
    }),
    clean: () => INITIAL_STATE
  }
})

export const load = slice.actions.load

export const received = slice.actions.received

export const clean = slice.actions.clean

export default slice.reducer
