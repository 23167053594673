export const numFormatter = number => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  const tier = Math.log10(number) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number.toString();

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export const formatGraphQLDate = date => {
  if (!date) return null

  const local = navigator.language || navigator.browserLanguage || 'fr'

  return new Date(date).toLocaleDateString(local)
}

export const getPageProps = ({ page = 1, itemsPerPage = 1}) => ({
  first: itemsPerPage,
  offset: (page - 1) * itemsPerPage,
})