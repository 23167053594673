import { createSlice, createSelector } from 'redux-starter-kit'
import { parse } from 'querystringify'
import { getPageProps } from '../Util/Helper'

const ITEMS_PER_PAGE = 10

export const INITIAL_STATE = {
  query: '',
  loading: true,
  bundles: [],
  loadingVersions: false,
  versions: {
    phpVersions: ["5.3", "5.4", "5.5", "5.6", "7.0", "7.1", "7.2", "7.3", "7.4", "8.0", "8.1", "8.2"],
  },
  filters: {
    phpVersion: '',
  },
  page: 1,
  total: null,
}

export const slice = createSlice({
  name: 'search',
  initialState: INITIAL_STATE,
  reducers: {
    parseLocation: (state, { payload }) => ({
        ...state,
        query: payload.q || state.query,
        page: Number(payload.page) || state.page,
        filters: {
          phpVersion: payload.phpVersion || '',
        },
      }
    ),
    changeQuery: (state, { payload }) => ({
      ...state,
      loading: true,
      bundles: [],
      query: payload.query,
    }),
    fetchBundles: state => ({
      ...state,
      loading: true,
    }),
    receivedBundles: (state, { payload: { packages, total } }) => ({
      ...state,
      loading: false,
      bundles: packages,
      total,
    }),
    fetchVersions: state => ({
      ...state,
      loadingVersions: true,
    }),
    receivedVersions: (state, { payload }) => ({
      ...state,
      loadingVersions: false,
      versions: payload || state.versions,
    }),
    filterPhpVersion: (state, { payload }) => ({
      ...state,
      filters: {
        phpVersion: payload.phpVersion,
        sfVersion: state.filters.sfVersion,
      },
    }),
    filterPage: (state, { payload }) => ({
      ...state,
      page: Number(payload.page),
    }),
    reset: () => INITIAL_STATE,
  },
})

export const {
  parseLocation,
  changeQuery,
  fetchBundles,
  receivedBundles,
  fetchVersions,
  receivedVersions,
  filterPhpVersion,
  filterPage,
  reset,
} = slice.actions

export const selectQuery = state => state.search.query
export const selectPhpVersion = state => state.search.filters.phpVersion
export const selectPage = state => state.search.page

const selectTotal = state => state.search.total
export const selectLastPage = createSelector(
  selectTotal,
  total => Math.ceil(total / ITEMS_PER_PAGE)
)

export const selectFilters = createSelector(
  selectQuery,
  selectPhpVersion,
  selectPage,
  (q, phpVersion, page) => {
    const { first, offset } = getPageProps({ page, itemsPerPage: ITEMS_PER_PAGE })

    return { q, phpVersion, first, offset }
  }
)

export const selectSearchParams = createSelector(
  selectQuery,
  selectPhpVersion,
  selectPage,
  (q, phpVersion, page) => ({ q, phpVersion, page })
)

export default slice.reducer
