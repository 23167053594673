import { all, fork } from 'redux-saga/effects'
import { partial } from '../Util/Functional'
import bundle from './Bundle'
import bundles from './Bundles'
import layout from './Layout'

export default function* (dependencies) {
  yield all([
    fork(partial([ dependencies ], bundle)),
    fork(partial([ dependencies ], bundles)),
    fork(partial([ dependencies ], layout)),
  ])
}
