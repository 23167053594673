const sortPhpVersions = versions => [ ...versions ].sort((a,b) => {
  const [ aMajor, aMinor ] = a.split('.')
  const [ bMajor, bMinor ] = b.split('.')

  if (aMajor === bMajor) {
    return bMinor - aMinor
  }

  return bMajor - aMajor
})

const formatBundles = bundles => bundles.map(b => ({
  ...b,
  phpVersionsSupported: sortPhpVersions(b.phpVersionsSupported),
}))

export const homeBundlesSelector = state => {
  const mostStarred = formatBundles(state.mostStarred.bundles)
  const mostDownloaded = formatBundles(state.mostDownloaded.bundles)

  return { mostStarred, mostDownloaded }
}

