import { LogoIcon } from './Header'
import { dotPath } from '../../Util/Functional'
import styled, { keyframes } from 'styled-components'

export const BlinkAnimation = keyframes`
  0% {
    opacity: .1
  }

  70% {
    opacity: .8
  }

  100% {
    opacity: .1
  }
`

export const Container = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${BlinkAnimation} 1.5s  infinite;

  img {
    position: absolute;
    top: 0;
    width: 64px;
    height: 64px;
    opacity: 1
  }

  ${LogoIcon} {
    color: ${dotPath('theme.colors.grey.light')};
    opacity: 1;
    position: relative;
    top: -1px;
    left: 1px;
  }
`
