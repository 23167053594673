import React from 'react'
import styled from 'styled-components'
import { dotPath } from '../../Util/Functional'

export const TableFlip = props => <SadFace {...props}>
  (ノಠ益ಠ)ノ彡┻━┻
</SadFace>

export const Shrug = props => <SadFace {...props}>
  ¯\_(ツ)_/¯
</SadFace>

export const SadFace = styled.div`
  text-align: center;
  font-size: 2rem;
  color: ${dotPath('theme.colors.grey.lightest')};
  font-family: "Montserrat", "sans-serif";
  font-weight: bold;
`
