/**
 * extract a member of an array or object
 * by specifying it's path as a string separated by dot's.
 * this function is curryied in order to be used
 * in `useSelector` redux hook.
 *
 * @example
 * ```js
 *  const data = {
 *    user: {
 *      books: [
 *        { title: 'Hamlet' },
 *      ]
 *    }
 *  }
 *
 *  const getFirstBookTitle = dotPath('user.books.0.title')
 *
 *  console.warn(getFirstBookTitle(data))
 * ```
 */
export const dotPath = path => subject => {
  const members = path.split('.').map(subject => subject.trim())

  return recGetMember(members, subject)
}

const recGetMember = (paths = [], subject = {}) => {
  if (0 === paths.length) {
    return subject
  }

  const key = paths[0]
  const newSubject = subject[key]

  return recGetMember(paths.slice(1), newSubject)
}


/**
 * print a given number into a smaller form:
 *
 * > 1000 became 1K
 * > 1 000 000 became 1M
 *
 * number are rounded to the nearest integer
 *
 * > 1890 became 2K
 */
export const displayNumber = (n) =>
  n >= 1000000
    ? `${Math.round(n / 1000000)}M`
    : n >= 1000
      ? `${Math.round(n / 1000)}K`
      : `${n}`

/**
 * Allows the execution of functions sequancialy
 *
 * @example
 *
 * const getLatestVersion = pipe([
 *  // Execute first dot path function
 *  dotPath('bundle.versions'),
 *  // Then find on the return value of "dotPath"
 *  find(version => version.latest),
 * ])
 *
 * const latestVersion = getLatestVersion({
 *  bundle: {
 *    versions: [
 *      { label: "1.0", latest: false },
 *      { label: "2.0", latest: true },
 *      { label: "1.1", latest: false },
 *    ]
 *  }
 * }) // Will return { label: "2.0", latest: true }
 */
export const pipe = functions => (...data) =>
  functions
    .reduce((value, f) => f.apply(null, value ? [ value ] : data), null)


/**
 * find a result from a array using data last and curry technics for
 * easy piping
 *
 * @example
 * const getLatestBundle = find(bundle => bundle.latest)
 * const latestBundle = getLatestBundle([
 *   { label: "1.0", latest: false },
 *   { label: "2.0", latest: true },
 *   { label: "1.1", latest: false },
 * ]) // returns "{ label: '2.0', latest: true }"
 */
export const find = f => data => data.find(f)

/**
 * same principle than find, but with map :)
 */
export const map = f => data => {
  if (!Array.isArray(data)) return []

  return data.map(f)
}

/**
 * also the same principle as map and find but for reduce
 */
export const reduce = f => data => data.reduce(f)


/**
 * Allow to `bind` a function in a curried and functional way
 *
 * @example
 * const john = { firstname: 'john', lastname: 'doe' }
 * const getFullName = ({ firstname, lastname }) => `${firstname} ${lastname}`
 * const getJohnFullName = partial([ john ], getFullName)
 *
 * console.warn(getJohnFullName()) // 'john doe'
 */
export const partial = (args, fn) => fn.bind(null, ...args)
