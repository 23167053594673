import { all, call, fork, select, takeLatest } from 'redux-saga/effects'
import { setTitle } from '../State/Layout'

export const selectTitle = state => state.layout.title
export const changeDocumentTitle = title => window.document.title = title

export function* changeTitle() {
  try {
    const title = yield select(selectTitle)

    yield call(changeDocumentTitle, title)
  } catch (error) {
    console.error('Unable to change page title')
  }
}

export function* setTitleSaga() {
  yield takeLatest(`${setTitle}`, changeTitle)
}

export default function* rootSaga () {
  yield all([
    fork(setTitleSaga),
  ])
}
